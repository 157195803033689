import React, { useState } from "react"
import axios from "axios"
import { useNavigate, redirect, Link } from "react-router-dom"
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PostData from "../services/PostData";

import Bread_Bskt from "../images/Bread_Bskt.png"


function Authorization({ login }) {

    const [emailInput, setEmailInput] = useState("")
    const [passInput, setPassInput] = useState("")

    const [fName, setfName] = useState("")
    const [lName, setlName] = useState("")
    const [signupClk, setSignupClk] = useState(false)
    const [error, setError] = useState(false)
    const [load, setLoad] = useState(false)

    const apiLink = 'api/login'

    const navigate = useNavigate()

    /*-----------------------Handle Login--------------------------*/
    async function HandleLogin(event) {

        event.preventDefault()

        try {

            const { success, error, usr, db, role, token } = await PostData(
                {
                    "email": emailInput,
                    "password": passInput
                }, apiLink, true)
            setLoad(true)


            if (success && (role === "GlobalAdmin" || role === "AppAdmin")) {
                sessionStorage.setItem("urtkn", token)
                setLoad(false)
                login(true, db, usr, role)
                navigate("/analytics")

            } else if (success && (role === "User")) {
                sessionStorage.setItem("urtkn", token)
                setLoad(false)
                login(true, db, usr, role)
                navigate("/inventory")
            }
            else {
                setLoad(false)
                // setEmailInput("")
                setPassInput("")
                setError(true)

                redirect("/login")
            }
        } catch (error) {
            redirect("/login")
        }

    }
    /*-----------------------Handle Register--------------------------*/
    async function HandleRegister(event) {
        event.preventDefault()
        try {

            const res = await axios.post(`${apiLink}/api/register`,

                {
                    "fName": fName,
                    "lName": lName,
                    "email": emailInput,
                    "password": passInput
                }, { withCredentials: true }, setLoad(true))

            if (res.status === 200) {

                setLoad(false)
                navigate("/")
            } else { setLoad(false) }
        } catch (err) {
            setLoad(false)
            redirect("/")
            console.log("Error on the signup response")
            console.log(err)
        }
    }


    function onSignup() {

        setSignupClk(prev => {
            return !prev
        })
    }
    /*-----------------------Render--------------------------*/
    if (load) {
        return (<div className="pg-spin" style={{ position: 'relative', top: '50%' }}><CircularProgress /></div>)
    }
    return (
        <>
            {error && <Alert sx={{ position: "relative", width: "25%", left: "38%", top: 30, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                severity="error">Incorrect Email or Password. Please try again</Alert>}
            {!signupClk ?

                <div className="row" >
                    <div className="col-12">
                        <div className="row justify-content-center align-items-center" style={{ height: "75vh" }}>

                            <div className="col-xl-4 col-md-10">
                                <div className="row justify-content-center align-items-center" style={{ padding: "20px 15px 0 15px" }}  >
                                    <div className="col-6 mx-auto" style={{ width: '200px', marginBottom: '20px' }}>
                                        <img src={Bread_Bskt} style={{ width: '115px', height: '110px', marginLeft: '32px' }} />
                                    </div>
                                </div>

                                {/*---------------- -------- -------- --------UNDER LOGO SECTION -------- -------- -------- --------  */}
                                <div className="row rst-pss"  >

                                    <div className="col-12 login-container">
                                        <div className='row' style={{ padding: "20px 15px 0 15px" }}>
                                            <p style={{ fontSize: '30px', height: '30px' }}>Welcome Back! </p>
                                            <hr style={{ borderWidth: '0.5px' }} />
                                        </div>


                                        <form className="login-form" onSubmit={HandleLogin}>

                                            <div className="row" style={{ width: '100%', paddingLeft: '20px', paddingBottom: "25px" }} >

                                                <TextField size="small" type="text" label="Email" required
                                                    onChange={(e) => setEmailInput(e.target.value)} value={emailInput}
                                                />

                                            </div>
                                            <div className="row" style={{ width: '100%', paddingLeft: '20px', paddingBottom: "25px" }} >

                                                <TextField size="small" type="password" label="Password" required
                                                    onChange={(e) => setPassInput(e.target.value)} value={passInput}
                                                />

                                            </div>

                                            <div className="row" style={{ width: "100%", padding: "0 10px", marginBottom: "15px" }} >

                                                <button className="submit" >
                                                    Login </button>

                                            </div>
                                        </form>

                                        <div className="row" style={{ paddingLeft: '15px', width: '200px', paddingBottom: '20px' }}>

                                            <Link to={"/reset-password"} style={{ textDecoration: 'none', fontSize: 'small' }}>Forgot Password?</Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >

                :

                <div className="row" style={{ position: 'relative', top: '45%', paddingLeft: '20px' }}>
                    <div className="col-12">
                        <div className="row justify-content-center" >
                            <div className="col-xl-4 col-md-6  wlcm">
                                <div className="row" style={{ paddingLeft: '15px' }}>
                                    <div className="col-12 offset-xl-4 offset-lg-4 offset-md-4" style={{ paddingTop: "50px" }}>
                                        <h1>Signup </h1>
                                    </div>
                                </div>
                                <div className="row" >
                                    <form onSubmit={HandleRegister} >

                                        <div className="row" style={{ paddingLeft: '30px' }}>
                                            <div className="col-12 offset-xl-3 offset-lg-3">
                                                <TextField size="small" type="text" label="First Name" required
                                                    onChange={(e) => setfName(e.target.value)} value={fName}
                                                    sx={{ marginBottom: '10px' }} />
                                            </div>

                                        </div>
                                        <div className="row" style={{ paddingLeft: '30px' }}>
                                            <div className="col-12 offset-xl-3 offset-lg-3">
                                                <TextField size="small" type="text" label="Last Name" required
                                                    onChange={(e) => setlName(e.target.value)} value={lName}
                                                    sx={{ marginBottom: '10px' }} />
                                            </div>

                                        </div>
                                        <div className="row" style={{ paddingLeft: '30px' }}>
                                            <div className="col-12 offset-xl-3 offset-lg-3">
                                                <TextField size="small" type="text" label="Email" required
                                                    onChange={(e) => setEmailInput(e.target.value)} value={emailInput}
                                                    sx={{ marginBottom: '10px' }} />
                                            </div>

                                        </div>
                                        <div className="row" style={{ paddingLeft: '30px' }}>
                                            <div className="col-12 offset-xl-3 offset-lg-3">
                                                <TextField size="small" type="password" label="Password" required
                                                    onChange={(e) => setPassInput(e.target.value)} value={passInput}
                                                    sx={{ marginBottom: '10px' }} />
                                            </div>

                                        </div>
                                        <div className="row" style={{ marginLeft: '25px' }}>
                                            <div className="col-12 offset-xl-3 offset-lg-3">
                                                <button className="auth-btn" style={{ backgroundColor: "black", color: "whitesmoke", border: 'none' }}>Signup </button>
                                            </div>
                                        </div>

                                    </form>
                                    <div className="row" style={{ paddingLeft: '15px' }}>
                                        <div className="col-12 offset-xl-3 offset-lg-3">
                                            <p> Already have an account? <button
                                                style={{ color: "blue", marginRight: 25, padding: 0, width: "24px", background: 'none', border: 'none' }}
                                                onClick={onSignup}>Login</button> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </>
    )
}

export default Authorization
