import React, { useState, useEffect, useContext } from 'react';
import TotInventory from '../components/Dashboard/TotInventory.jsx';
import HighestMenuItem from '../components/Dashboard/HighestMenuItem'
import LowestMenuItem from '../components/Dashboard/LowestMenuItem'
import ExpensiveIngredient from '../components/Dashboard/ExpensiveIngredient'
import DateFilter from '../components/Menu/DateFilter';
import VendorTable from '../components/Dashboard/VendorTable.jsx';
import CategoryTable from '../components/Dashboard/CategoryTable.jsx';
import VendorLineGraph from '../components/Dashboard/VendorLineGraph.jsx';
import CircularProgress from '@mui/material/CircularProgress';


const Dashboard = ({ reload }) => {

    const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }
    const [load, setLoad] = useState(true)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [showLoad, setShowLoad] = useState(false)
    const [reloadDash, setReloadDash] = useState(reload)

    useEffect(() => {
        if (startDate === null && endDate === null) {
            initializeDates()
        }
        setLoad(false)

    }, [startDate, endDate])

    useEffect(() => {
        setReloadDash(reload)
    }, [reload])

    const initializeDates = () => {
        let todayDate = new Date()

        const lastWeekDate = new Date(todayDate);
        lastWeekDate.setDate(todayDate.getDate() - 30);
        todayDate = todayDate.toLocaleDateString('en-US', dateOptions)
        const dateOne = lastWeekDate.toLocaleDateString('en-US', dateOptions)

        setStartDate(dateOne)
        setEndDate(todayDate)
        return
    }

    const dateClicked = async (start, end) => {
        setShowLoad(true)
        setStartDate(start)
        setEndDate(end)
        setReloadDash(true)
        setTimeout(() => {
            setReloadDash(false)
        }, 200)

    }

    if (load) {
        return (<div className="pg-spin"><CircularProgress /></div>)
    }

    return (
        <>
            <div className='sticky-header'>
                <div className='row pg-hdr' style={{ width: "100%" }}>
                    <div className="col-xl-1 col-lg-1 col-md-1" >
                        <h2 style={{ marginBottom: "0" }}>Analytics</h2>
                    </div>

                    <div className='col-xl-11 col-lg-11 col-md-11'>
                        <DateFilter ingredient='null' dates={dateClicked} style="dashboard" defaultDays={30} showLoading={showLoad} />
                    </div>
                </div>

                <hr style={{ marginTop: '0', marginBottom: "15px" }} />
            </div>

            {/*-----------------------------------------HIGH-LEVEL--------------------------------------------- */}
            <div className='row' style={{ padding: "0 8px", justifyContent: "space-evenly", marginBottom: "30px" }}>
                <div className='col-xl-2 col-lg-6 col-md-6 dsh dsh-hd' style={{ paddingRight: "0" }}>
                    <TotInventory startDate={startDate} endDate={endDate} reload={reloadDash} showLoad={() => setShowLoad(false)} />
                </div>
                <div className='col-xl-2 col-lg-6 col-md-6 dsh dsh-hd' style={{ paddingRight: "0" }}>
                    <HighestMenuItem reload={reloadDash} />
                </div>
                <div className='col-xl-2 col-lg-6 col-md-6 dsh dsh-hd' style={{ paddingRight: "0" }}>
                    <LowestMenuItem reload={reloadDash} />
                </div>
                <div className='col-xl-2 col-lg-6 col-md-6 dsh dsh-hd' style={{ paddingRight: "0" }}>
                    <ExpensiveIngredient startDate={startDate} endDate={endDate} reload={reloadDash} showLoad={() => setShowLoad(false)} />
                </div>
            </div>
            {/*-----------------------------------------TABLES----------------------------------------------- */}
            <div className='row' style={{ padding: "0 20px", paddingLeft: "22px", justifyContent: "space-around", gap: "20px", marginBottom: "30px", overflowY: 'scroll' }}>
                <div className='col-lg-5 col-md-11 col-sm-12 dsh dsh-tbl' style={{ overflowY: 'scroll' }}>
                    <VendorTable startDate={startDate} endDate={endDate} /*location={userLocs}*/ reload={reloadDash} />
                </div>
                <div className='col-lg-5 col-md-11 col-sm-12 dsh dsh-tbl' style={{ overflowY: 'scroll' }}>
                    <CategoryTable startDate={startDate} endDate={endDate}/*location={userLocs}*/ reload={reloadDash} />
                </div>

            </div>

            {/*------------------------------------------GRAPH------------------------------------------------- */}
            <div className='row' style={{ justifyContent: "space-around", padding: "0 20px 0 25px", marginBottom: '100px', height: '400px' }}>
                <div className='col-12 dsh dsh-grph'>
                    <VendorLineGraph startDate={startDate} endDate={endDate} reload={reloadDash} />
                </div>
            </div>
        </>
    );
};

export default Dashboard
