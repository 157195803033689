import React, { useEffect, useState } from 'react'
import useFetch from '../../../../services/useFetch.js';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

export default function AddModifiers({ ingredientId, updateModifier }) {

    const [load, setLoad] = useState(true)
    const { data, error } = useFetch(`api/menuItemsRecipe/${ingredientId}`, false)

    const [modifiers, setModifiers] = useState([
        {
            modifiers: [{
                modifierName: "",
                modifierId: "",
                modifierPrice: 0,
                modOverride: false,
                ingredients: [{
                    name: "",
                    id: 0,
                    units: [{ amount: "", measurement: "" }]
                }]
            }]
        }
    ]);

    useEffect(() => {

        if (data !== null) {
            setLoad(false)

            if (data && data.modifiers) {
                setModifiers(data.modifiers);
                updateModifier(data.modifiers)
            } else {
                console.log("No ingredients found");
            }
        }
    }, [data])

    const [selectedData, setSelectedData] = useState(false)

    const handleAmountChange = (ingredientIndex, { target }, modifierIndex) => {

        setSelectedData(true)

        setModifiers(currentModifiers => {
            // Make a deep copy to ensure no mutation
            const newModifiers = currentModifiers.map((mod, idx) => {
                if (idx === ingredientIndex) {
                    return {
                        ...mod,
                        ingredients: mod.ingredients.map((ing, i) => {
                            if (i === modifierIndex) {
                                return {
                                    ...ing,
                                    units: ing.units.map(unit => ({
                                        ...unit,
                                        amount: parseFloat(target.value)  // Update the measurement for all units
                                    }))
                                };
                            }
                            return ing;
                        })
                    };
                }
                return mod;
            });
            updateModifier(newModifiers)
            return newModifiers;
        });
    }

    const handleCheck = (e, val, index) => {
        const newModifier = [...modifiers]
        newModifier[index]["modOverride"] = !newModifier[index]["modOverride"]
        updateModifier(newModifier)
    }

    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <>
            {
                data?.modifiers &&
                <div>

                    <div className='row' style={{ margin: "20px 0 0 20px" }}>
                        <p style={{ fontSize: '20px' }}>Modifier Ingredients:</p>
                    </div>

                    {modifiers.map((elem, ind) => (
                        <div className='row' style={{ marginTop: "10px", marginBottom: '20px' }} key={ind}>
                            <div className='row'>
                                <div className='col-6' style={{ maxWidth: '745px' }}>

                                    <TextField helperText={"Modifier Name"} style={{ margin: "0 0 20px 40px", width: "fit-content" }}
                                        value={elem.modifierName} name='itemName' disabled
                                        size='small' variant='standard'
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000"
                                            },
                                            "& .MuiFormHelperText-root.Mui-disabled": {
                                                WebkitTextFillColor: "#000000"
                                            }
                                        }}
                                    />

                                </div>
                                <div className='col-3'>
                                    <Checkbox onChange={(e, val) => handleCheck(e, val, ind)} /> <label>Mod. Override</label>
                                </div>
                            </div>

                            {elem.ingredients && elem.ingredients.map((ingredient, ingIndex) => (
                                <div className='row' style={{ margin: "0 25px 10px 25px" }}>
                                    <div className='col-3'>

                                        <TextField placeholder={"Modifier Ingredient"}
                                            style={{ width: "100%" }} size='small'
                                            value={ingredient.name}
                                            disabled
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#656565"
                                                },

                                            }}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <TextField placeholder='Add Ingredient Units...' style={{ width: "100%" }} size='small'
                                            type="number" onChange={(e) => handleAmountChange(ind, e, ingIndex)}
                                            value={ingredient.units[0].amount}
                                        />
                                    </div>


                                    <div className='col-2'>
                                        <TextField style={{ width: "100%" }} value={ingredient.units[0].measurement} size='small'
                                            placeholder={"Ingredient Measurement"} type="text" disabled
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#656565"
                                                }
                                            }} />
                                    </div>

                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            }
        </>
    );
};
