import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useFetch from "../../../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';
import { handleQuickBooksAuth } from '../../Account/AccountPages/QuickBooksOAuth.js';
import QuickbooksItems from './QuickbooksItems.jsx';
import QuickbooksVendors from './QuickbooksVendors.jsx';

export default function QuickbooksSetupPage({ reload }) {
    const [value, setValue] = useState(0);

    const { data: qboExists, load, error } = useFetch("api/check-quickbooks-exists", reload)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabPage = (index) => {
        switch (index) {
            case 0:
                return (<QuickbooksItems />);
            case 1:
                return (<QuickbooksVendors />);
        }
    }
    if (load) {
        <div>
            <div className="sticky-header">
                <div className='row pg-hdr' style={{ width: "100%" }}>
                    <div className="col-xl-9 col-lg-8 col-md-8" >
                        <h2>Quickbooks Setup</h2>
                        <p style={{ marginBottom: '0' }}>Integrate and merge items from QBO</p>
                    </div>
                </div>
                <hr style={{ margin: '0', marginBottom: '0', width: "100%" }} />
            </div>
            <CircularProgress />
        </div>
    }

    return (
        <div>
            <div className="sticky-header">
                <div className='row pg-hdr' style={{ width: "100%" }}>
                    <div className="col-xl-9 col-lg-8 col-md-8" >
                        <h2>Quickbooks Setup</h2>
                        <p style={{ marginBottom: '0' }}>Integrate and merge items from QBO</p>
                    </div>
                </div>
                <hr style={{ margin: '0', marginBottom: '0', width: "100%" }} />
            </div>

            {!qboExists ?
                <div>
                    <div style={{ position: 'relative', left: '40%', marginTop: '10%' }}>
                        <button
                            onClick={() => handleQuickBooksAuth()}
                            style={{ width: '205px', background: '#2baeed' }}
                            className='submit'>Connect to Quickbooks</button>
                    </div>
                </div>
                :
                <>
                    <Tabs value={value} onChange={handleChange} sx={{ marginLeft: '18px' }}>
                        <Tab label="Inventory Items" sx={{ textTransform: 'none' }} />
                        <Tab label="Vendors" sx={{ textTransform: 'none' }} />
                    </Tabs>
                    <hr style={{ margin: '0', marginBottom: '5px' }} />
                    {tabPage(value)}
                </>
            }
        </div>
    );
};
