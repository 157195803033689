import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useFetchWithParams from '../../services/useFetchWithParams.js'
import PostData from '../../services/PostData.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { UoMMenuOptions } from '../Helpers/UoMMenuOptions.js';

const ingredientAPIUrl = "api/ingredient-data"
export default function EditRawIngredientInfo({ ingredientId, vendorId, close }) {

    const { data: ingredientInfo, load: ingredientEditLoad } = useFetchWithParams(ingredientAPIUrl, { ingredientId: ingredientId, vendorId: vendorId })
    const [ingredientData, setIngredientData] = useState(null)
    const [isFocused, setIsFocused] = useState(false);
    const [processingEdit, setProcessingEdit] = useState(false)
    const [fndItemUoM, setFoundItemUoM] = useState([]);
    useEffect(() => {

        if (ingredientInfo !== null) {
            setIngredientData(ingredientInfo)
            setFoundItemUoM([ingredientInfo.parUoM])
            if (ingredientInfo.singleItemSize) {
                setIsFocused(true)
            }
        }

    }, [ingredientInfo])

    const recipeUoM = ["oz", "floz", "tsp", "tbsp", "mL", "grams", "cup", "units", "none"]
    function findItemUoM(key) {

        const obj = UoMMenuOptions(key)

        if (obj) {
            const val = obj[key];
            setFoundItemUoM(val);
        } else {
            setFoundItemUoM([]);
        }
    }

    const menuCategories = [
        "Coffee",
        "Dairy",
        "Disposables",
        "Dry Goods",
        "Tea",
        "Meat",
        "Produce",
        "Bread",
        "Chemicals/Cleaning",
        "Retail"]

    const closePop = () => {
        close(false)
    }

    const handleInput = ({ target }, fieldName) => {
        let value
        switch (fieldName) {
            case "parStock":
            case "singleItemSize":
                value = Number(target.value)
                break;

            case "recipeUoM":
                value = target.value
                findItemUoM(target.value)
                break;
            default:
                value = target.value
                break;
        }
        setIngredientData(currVals => ({ ...currVals, [fieldName]: value }))
    }

    const handleSubmit = async () => {
        // Post data to editRawIngredient
        setProcessingEdit(true)
        const { success, error } = await PostData(ingredientData, 'api/edit-raw-ingredient', false)
        if (success) {
            setProcessingEdit(false)
            close("parDetails")
        } else if (error) {
            setProcessingEdit(false)
            close("ingredient failure")
        }
    }

    const handleBlur = () => {
        if (!ingredientData["singleItemSize"]) {
            setIsFocused(false);
        }
    };

    if (ingredientEditLoad || processingEdit) {
        return (<div className="container"><CircularProgress /></div>)
    }

    return (
        <div style={{ height: "60vh", overflowY: 'scroll', overflowX: 'hidden' }}>
            <div className='row' style={{ width: '100%', top: "3%", left: "5%", position: "relative" }}>
                <div className='col-9'>
                    <h4 style={{ marginBottom: "10px" }}>Edit Ingredient: </h4>
                </div>
                <div className='col-3' style={{ position: 'relative', left: "70px", marginBottom: "10px" }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={closePop} />
                    </button>
                </div>

            </div>
            <hr style={{ marginBottom: "35px" }} />

            {ingredientData &&
                <>
                    <div className='row' style={{ marginBottom: "15px", marginLeft: "7.5%", alignItems: "center", width: "100%" }}>

                        <div className='col-9'>
                            <TextField
                                label={"Raw Ingredient"}
                                size="small"
                                variant="outlined" placeholder="Raw Ingredient" style={{ width: "365px" }}
                                name='rawIngredient'
                                value={ingredientData["ingredient"] || ""}
                                onChange={(e) => handleInput(e, "ingredient")} />
                        </div>
                    </div>

                    <div className='row' style={{ marginBottom: "15px", marginLeft: "7.5%", alignItems: "center", width: "100%" }}>

                        <div className='col-9'>
                            <TextField
                                label={"Supplier Item Id"}
                                size="small"
                                variant="outlined" placeholder="Item Id" style={{ width: "365px" }}
                                value={ingredientData["vendorIngredientID"] || ""}
                                name='vendorIngredientID'
                                onChange={(e) => handleInput(e, "vendorIngredientID")} />
                        </div>
                    </div>

                    <div className='row' style={{ marginBottom: "15px", marginLeft: "7.5%", alignItems: "center", width: "100%" }}>

                        <div className='col-9'>
                            <TextField
                                label={"Store Item Name"}
                                size="small"
                                variant="outlined" placeholder="Store name" style={{ width: "365px" }}
                                name='storeName'
                                value={ingredientData["storeName"] || ""}
                                onChange={(e) => handleInput(e, "storeName")} />
                        </div>

                    </div>

                    <div className='row' style={{ marginBottom: "15px", marginLeft: "7.5%", alignItems: "center" }}>

                        <div className='col-9'>
                            <Autocomplete

                                size="small"
                                options={menuCategories}
                                name='category'
                                value={ingredientData["category"] || ""}
                                onChange={(e, val) => setIngredientData({ ...ingredientData, ['category']: val })}
                                sx={{ width: "365px" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Category"}
                                        placeholder='Coffee, Dairy...'
                                    />
                                )}>
                            </Autocomplete>
                        </div>


                    </div>

                    <div className='row' style={{ marginBottom: "15px", marginLeft: "7.5%", alignItems: "center", width: "100%" }}>

                        <div className='col-9'>
                            <TextField
                                label={"Item Packaging"}
                                size="small"
                                variant="outlined" placeholder="Bag, Cup, Carton" style={{ width: "365px" }}
                                name='parPackaging'
                                value={ingredientData["parPackaging"] || ""}
                                onChange={(e) => handleInput(e, "parPackaging")} />
                        </div>

                    </div>

                    <div className='row' style={{ marginBottom: "15px", marginLeft: "7.5%", alignItems: "center", width: "100%" }}>

                        <div className='col-9'>
                            <TextField
                                label={"Stock Remaining"}
                                size="small"
                                type='number'
                                variant="outlined" placeholder="Stock" style={{ width: "365px" }}
                                name='parStock'
                                value={ingredientData["parStock"] !== null ? ingredientData["parStock"] : ""}
                                onChange={(e) => handleInput(e, "parStock")} />
                        </div>

                    </div>

                    <div className='row' style={{ marginBottom: "25px", marginLeft: "7.5%", alignItems: "center", width: "100%" }}>

                        <div className='col-2' style={{ marginRight: "10px", width: '115px' }}>
                            <TextField
                                select
                                label={"RecipeUoM"}
                                size="small"
                                variant="outlined" placeholder="RecipeUoM" style={{ width: "115px" }}
                                name='recipeUoM'
                                value={ingredientData["recipeUoM"] || ""}
                                onChange={(e) => handleInput(e, "recipeUoM")} >
                                {recipeUoM.map((option, ind) => (

                                    <MenuItem key={ind} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className='col-2' style={{ marginRight: "10px", width: '115px' }}>
                            <TextField
                                select
                                label={"ItemUoM"}
                                size="small"
                                variant="outlined" placeholder="ItemUoM" style={{ width: "115px" }}
                                name='ItemUoM'
                                value={ingredientData["parUoM"] || ""}
                                onChange={(e) => handleInput(e, "parUoM")} >
                                {fndItemUoM?.map((option, ind) => (

                                    <MenuItem key={ind} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className='col-2'>
                            <TextField
                                label={"Item Size"}
                                size="small"
                                variant="outlined" placeholder="Size" style={{ width: "115px" }}
                                name='itemSize'
                                onFocus={() => setIsFocused(true)}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: isFocused && ingredientData.parUoM ? (
                                        <InputAdornment position="end">{ingredientData.parUoM}</InputAdornment>
                                    ) : null, // Show adornment only when focused
                                }}
                                value={ingredientData["singleItemSize"] || null}
                                onChange={(e) => handleInput(e, "singleItemSize")} />
                        </div>

                    </div>

                    <div className='row' style={{ paddingBottom: "20px", justifyContent: 'center', position: "relative" }}>
                        <button className='submit' type='submit' style={{ width: "80%" }} onClick={handleSubmit} >
                            Submit
                        </button>
                    </div>
                </>
            }
        </div >
    );
};
