/* Full screen ordering -- select the vendor and add item, qty, price */
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import useFetch from "../../services/useFetch.js"
import GetData from '../../services/GetData.js';
import PostData from '../../services/PostData.js';
import SnackbarEx from '../Helpers/SnackbarEx.jsx';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import Menu from '@mui/material/Menu';
import { DateField } from '@mui/x-date-pickers';
import { states } from '../Inventory/UsStates.js';
import { Dialog } from '@mui/material';
import UploadOrderDetails from './UploadOrderDetails.jsx';

export default function PlaceInventoryOrder({ close }) {

    const { data: vendors, load, error: vendorErr } = useFetch("api/vendors-email-order", false)
    const { data: business, load: businessLoad, error: businessErr } = useFetch("api/business-information/place-order", false)
    const [submitLoad, setSubmitLoad] = useState(false)
    const stateArr = states.sort()
    const usrLocation = localStorage.getItem('usrlctn')
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })
    const [openUploadOrderDetails, setOpenUploadOrderDetails] = useState(false)

    const [ingredientList, setIngredientList] = useState([])
    const [ingNames, setIngNames] = useState(null)

    const [startDate, setStartDate] = useState(null)
    const [startAnchorElem, setStartAnchorElem] = useState(null);
    const openStartCal = Boolean(startAnchorElem);

    const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }

    const [orderDetails, setOrderDetails] = useState({
        "vendorName": null,
        "vendorId": null,
        "address": null,
        "locationName": usrLocation,
        "inventoryOrder": [],
        "deliveryDate": null,
        "totalOrderCost": null
    })

    const unitType = ["Individual", "Case"]

    useEffect(() => {
        if (orderDetails["vendorId"]) {
            retrieveRawItems()
        }

    }, [orderDetails])
    useEffect(() => {
        if (business) {
            setOrderDetails(currVals => ({ ...currVals, ["address"]: business }))
        }
    }, [business])

    const handleVendorSelection = (e, val) => {
        if (orderDetails.inventoryOrder.length === 0) {
            setOrderDetails(currVals => ({ ...currVals, ["vendorName"]: val.vendor, ["vendorId"]: val.vendorId }))
            onAdd()
        } else {
            setOrderDetails(currVals => ({ ...currVals, ["vendorName"]: val.vendor, ["vendorId"]: val.vendorId }))
        }
    }

    const handleItemChoice = (e, val, index) => {
        const newItem = [...orderDetails["inventoryOrder"]]
        newItem[index]["ingredient"] = val.ingredient ?? val
        const ingrId = ingredientList.findIndex(item => item.ingredient === val)
        ingrId >= 0 ? newItem[index]["ingredientId"] = ingredientList[ingrId]["ingredientId"] : newItem[index]["ingredientId"] = null
        ingrId >= 0 && ingredientList[ingrId]["vendorIngredientID"] ? newItem[index]["vendorIngredientID"] = ingredientList[ingrId]["vendorIngredientID"]
            :
            newItem[index]["vendorIngredientID"] = orderDetails["inventoryOrder"][index]["vendorIngredientID"]
        setOrderDetails(currVals => ({ ...currVals, inventoryOrder: newItem }))
    }


    const handleInputChange = ({ target }, index, key) => {

        const newItem = [...orderDetails["inventoryOrder"]]
        if (key === "unitType" || key === "vendorIngredientID") {
            newItem[index][key] = target.value
        } else if (key === "price") {
            newItem[index][key] = Number(target.value)
            totalSum()
        }
        else {
            newItem[index][key] = Number(target.value)
        }
        setOrderDetails(currVals => ({ ...currVals, inventoryOrder: newItem }))
    }


    const handleDateDropdown = (elem) => {
        const elClicked = elem.currentTarget
        setStartAnchorElem(elClicked)
    }

    const handleDateSelection = (e) => {
        setStartDate(e)
        const newItem = [...orderDetails["inventoryOrder"]]
        const date = new Date(e)
        const reformDate = date.toLocaleDateString('en-US', dateOptions)
        setOrderDetails(currVals => ({ ...currVals, ["deliveryDate"]: reformDate }))
    }

    const handleStartDate = ({ target }) => {
        if (!target.dataset['timestamp']) { return }
        const selDate = target.dataset['timestamp']
        const date = new Date(parseInt(selDate))
        const reformDate = date.toLocaleDateString('en-US', dateOptions)

        setStartDate(reformDate)
        setStartAnchorElem(false)

        setOrderDetails(currVals => ({ ...currVals, ["deliveryDate"]: reformDate }))
    }

    async function retrieveRawItems() {
        const { getData: ingredientsByVendor, error: ingredientErr } = await GetData("api/ingredient-by-vendor", { "vendorId": orderDetails.vendorId })

        if (ingredientsByVendor) {
            setIngredientList(ingredientsByVendor)
            const ingredientNames = ingredientsByVendor.map(item => item.ingredient);
            setIngNames(ingredientNames)
        }
    }

    const totalSum = () => {
        const tot = orderDetails["inventoryOrder"].reduce((total, row) => parseFloat(total) + (parseFloat(row.price) || parseFloat(0)), 0);
        const newSumTotal = Number(tot)

        setOrderDetails(currVals => ({ ...currVals, ["totalOrderCost"]: newSumTotal.toFixed(2) }))
    }

    const closePop = () => {
        close(false)
    }

    function onAdd() {
        setOrderDetails(prevOrderDetails => ({
            ...prevOrderDetails,
            inventoryOrder: [
                ...prevOrderDetails.inventoryOrder,
                {
                    ingredient: "",
                    ingredientId: null,
                    vendorIngredientID: null,
                    quantityOrder: null,
                    unitType: null,
                    // caseUnits: null,
                    price: null
                }
            ]
        }));
    }

    const deleteRow = (index) => {

        const orderList = [...orderDetails.inventoryOrder]
        const last = orderDetails.inventoryOrder.length - 1

        if (index !== 0) {
            const indFrom = orderList.splice(0, index)
            const indTo = orderList.splice(index, last)
            const newList = indFrom.concat(indTo)
            const tot = newList.reduce((total, row) => parseFloat(total) + (parseFloat(row.price) || parseFloat(0)), 0);
            const newSumTotal = Number(tot)

            setOrderDetails(prevOrderDetails => ({
                ...prevOrderDetails,
                inventoryOrder: newList,
                totalOrderCost: newSumTotal
            }))

        } else {
            setOrderDetails(prevOrderDetails => ({
                ...prevOrderDetails,
                inventoryOrder: orderList.splice(1, last)
            }))
        }
    }

    const handleUpload = async () => {
        if (!orderDetails.vendorName) {
            setOpenTestSnack({ color: "error", message: "Select a vendor to enable", open: true })
        } else {
            setOpenUploadOrderDetails(true)
        }
    }

    const handleUploadItems = (val) => {
        setOrderDetails(prevOrderDetails => ({
            ...prevOrderDetails,
            inventoryOrder: val
        }))
    }

    const closeOpenDialog = (val) => {
        if (val === "exit") {
            setOpenUploadOrderDetails(false)
        }
    }

    const handleSubmit = async () => {
        if (orderDetails.vendorName && orderDetails.locationName && orderDetails.deliveryDate && orderDetails.address && orderDetails.inventoryOrder.length > 0) {
            setSubmitLoad(true)
            const { success: orderPlaced, error: orderErr } = await PostData(orderDetails, "api/place-inventory-order", false)
            if (orderErr) {
                const errMessage = orderErr.response.data
                setOpenTestSnack({ color: "error", message: errMessage, open: true })
                setSubmitLoad(false)
                setOrderDetails(prevOrderDetails => ({
                    ...prevOrderDetails,
                    inventoryOrder: orderDetails.inventoryOrder
                }))
                // close('failure')
            }
            else {
                setSubmitLoad(false)
                close('success_order')
            }
        } else {
            setOpenTestSnack({ color: "error", message: "Please fill out all information!", open: true })
        }
    }

    if (load || businessLoad) {
        return (<div className="container"><CircularProgress /></div>)
    } else if (submitLoad) {
        return (
            <div>
                <div className='row inv-hdr' style={{ alignItems: 'baseline' }}>
                    <div className='col-10' >
                        <h4 style={{ margin: "20px 0 20px 5%" }}>Order inventory</h4>
                    </div>
                    <div className='col-2' style={{ position: 'relative', left: '10%' }}>
                        <button type='button' className='blank-bttn' onClick={closePop}>
                            <CloseIcon sx={{ cursor: 'pointer' }} />
                        </button>
                    </div>
                </div>
                <div className="container" style={{ left: '40%', top: '100%' }}><CircularProgress sx={{ marginLeft: '30px', marginBottom: '10px' }} />
                    <p style={{ fontSize: '14px' }}>Sending order...</p>
                </div>
            </div>
        )
    }
    return (
        <div >
            <div className='row inv-hdr' style={{ alignItems: 'baseline' }}>
                <div className='col-10' >
                    <h4 style={{ margin: "20px 0 20px 5%" }}>Order inventory</h4>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '10%' }}>
                    <button type='button' className='blank-bttn' onClick={closePop}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>
            </div>

            {/* ------------------------------------------ SELECT THE VENDOR TO ORDER FROM ------------------------------------ */}
            <div className='row' style={{ padding: '2% 0', marginBottom: '25px' }}>
                <div className='row' style={{ position: 'relative', top: '25%', left: '4.5%', width: '100%', minWidth: '800px' }}>
                    <div className='col-xl-3' style={{ paddingLeft: "0", maxWidth: '200px' }}>
                        <TextField
                            required
                            label={"Store name"}
                            variant='standard'
                            value={orderDetails?.locationName ?? ""}
                            onChange={(e) => setOrderDetails(currVals => ({ ...currVals, ["locationName"]: e.target.value }))}
                            sx={{

                                width: '200px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '5px',
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                },
                                '.MuiInput-input': {
                                    fontSize: "small"
                                }, '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" }
                            }}
                            size='small' />

                        {/* </div> */}
                    </div>
                    {/* ------------------------------------------ PLACE ORDER / UPLOAD BUTTONS ------------------------------------ */}
                    <div className='col-2 ms-auto' style={{ marginLeft: "15%", marginRight: '0', width: '158px' }}>
                        <button className='submit' type='submit' style={{ width: "150px", height: '36px', background: 'white', color: "#1fb6ff", border: '1px solid #1fb6ff' }} onClick={handleUpload}>
                            Upload
                        </button>
                    </div>
                    <div className='col-2' style={{ marginRight: "5%" }}>
                        <button className='submit' type='submit' style={{ width: "150px", height: '36px' }} onClick={handleSubmit}>
                            Place Order
                        </button>
                    </div>
                </div>
                {/* ------------------------------------------------------------------------------------------------------------------ */}
                <div className='row' style={{ position: 'relative', top: '25%', left: '4.5%', width: '100%', minWidth: '800px', alignItems: 'end', marginTop: '10px' }}>
                    <div className='col-xl-3' style={{ paddingLeft: "0", maxWidth: '215px' }}>
                        <Autocomplete
                            required
                            value={vendors?.find(vendor => vendor?.vendor === orderDetails?.vendorName) || null}
                            options={vendors ?? []}
                            getOptionLabel={(opts) => opts?.vendor}
                            onChange={(e, val) => handleVendorSelection(e, val, 'vendor')}
                            sx={{

                                width: '200px',
                                '.MuiInputBase-root': {
                                    borderRadius: '5px'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                },
                                '.MuiInput-input': {
                                    fontSize: "small"
                                }, '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" }
                            }}
                            size='small'

                            renderInput={(params) => (
                                <TextField
                                    variant='standard'
                                    label="Vendor name"
                                    {...params}
                                />
                            )}>
                        </Autocomplete>
                    </div>
                    <div className='col-3'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                variant='standard'
                                label="Delivery Date"
                                value={startDate ? dayjs(startDate) : ""}
                                sx={{
                                    '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <button className='blank-bttn' onClick={handleDateDropdown}>
                                                <CalendarMonthIcon onChange={(e) => handleDateSelection(e)} />
                                            </button>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => handleDateSelection(e)}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className='row' style={{ position: 'relative', top: '25%', left: '4.5%', width: '100%', minWidth: '800px', alignItems: 'end', marginTop: '10px' }}>
                    <div className='col-xl-3' style={{ paddingLeft: "0", maxWidth: '405px' }}>
                        <TextField
                            required
                            label={"Delivery address"}
                            variant='standard'
                            value={orderDetails?.address ?? ""}
                            onChange={(e) => setOrderDetails(currVals => ({ ...currVals, ["address"]: e.target.value }))}
                            sx={{

                                width: '405px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '5px',
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                },
                                '.MuiInput-input': {
                                    fontSize: "small"
                                }, '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" }
                            }}
                            size='small' />
                    </div>
                </div>


            </div>

            {/* ------------------------------------------ SELECT ITEMS TO ORDER FROM THE  ------------------------------------ */}
            <div className='row' style={{ fontSize: 'small', marginTop: '2px' }}>
                <div className='col-lg-3 col-md-2' style={{ padding: '0', marginLeft: '5%', width: '20%' }}>Item Name</div>
                <div className='col-2' style={{ padding: '0' /*, width: '200px'*/ }}>Product ID</div>
                <div className='col-2' style={{ padding: '0' /*, width: '200px'*/ }}>Unit Type</div>
                <div className='col-1' style={{ padding: '0'/*, width: '200px'*/ }}>Quantity</div>
                <div className='col-1' style={{ padding: '0'/*, width: '200px'*/ }}>Case units</div>
                <div className='col-2' style={{ padding: '0' }}>Unit Price</div>
            </div>

            <hr />

            <div className='row'>
                {orderDetails["inventoryOrder"].map((elem, indx) =>
                    <div className='row' style={{ marginBottom: "10px" }} key={indx}>
                        <div className='col-3' style={{ width: '21%' }}>
                            <Autocomplete
                                freeSolo
                                size='small'
                                value={orderDetails.inventoryOrder[indx]?.ingredient ?? ""}
                                onInputChange={(e, val) => handleItemChoice(e, val, indx)}
                                options={ingNames}
                                // getOptionLabel={(opt) => opt.ingredient}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='standard'
                                        sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                                sx={{ marginLeft: '14%' }}
                            />
                        </div>

                        <div className='col-2' id='stck-order-marg'>

                            <TextField className="inv-hd" variant="standard"

                                size='small'
                                value={orderDetails.inventoryOrder[indx]?.vendorIngredientID ?? ""}
                                onChange={(e) => handleInputChange(e, indx, 'vendorIngredientID')}

                                sx={{ width: '150px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }}>
                            </TextField>
                        </div>

                        <div className='col-2' >

                            <TextField className="inv-hd" variant="standard"
                                select
                                size='small'
                                value={orderDetails.inventoryOrder[indx]?.unitType ?? ""}
                                onChange={(e) => handleInputChange(e, indx, 'unitType')}

                                sx={{ width: '150px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }}>
                                {unitType.map((option, ind) => (
                                    <MenuItem key={ind} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div className='col-1' >

                            <TextField className="inv-hd" variant="standard"
                                size='small'
                                value={orderDetails.inventoryOrder[indx]?.quantityOrder ?? ""}
                                // value={orderDetails["inventoryOrder"][indx]["quantityOrder"] || ''}
                                onChange={(e) => handleInputChange(e, indx, 'quantityOrder')}
                                InputProps={{
                                    endAdornment: <InputAdornment sx={{ ".MuiTypography-root": { fontSize: "small" } }}
                                        position="end">
                                        {orderDetails.inventoryOrder[indx]["unitType"] === "Individual" ? "Units" : "Cases"
                                        }</InputAdornment>,
                                }}
                                sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }}>

                            </TextField>
                        </div>

                        <div className='col-1' >

                            <TextField className="inv-hd" variant="standard"
                                size='small'
                                disabled={orderDetails.inventoryOrder[indx]["unitType"] === "Individual"}
                                value={orderDetails.inventoryOrder[indx]?.caseUnits ?? ""}
                                // value={orderDetails["inventoryOrder"][indx]["quantityOrder"] || ''}
                                onChange={(e) => handleInputChange(e, indx, 'caseUnits')}
                                InputProps={{
                                    endAdornment: orderDetails.inventoryOrder[indx]["unitType"] === "Case" ? (
                                        <InputAdornment sx={{ ".MuiTypography-root": { fontSize: "small" } }} position="end">
                                            units
                                        </InputAdornment>
                                    ) : null,
                                }}
                                sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }}>

                            </TextField>
                        </div>

                        <div className='col-2' style={{ marginLeft: "5px", width: '200px' /*,  marginRight: "25px" */ }}>

                            <TextField className="inv-hd" variant="standard"
                                size='small'
                                type='number'
                                value={orderDetails["inventoryOrder"][indx]["price"] || ''}
                                onChange={(e) => handleInputChange(e, indx, 'price')}
                                InputProps={{
                                    startAdornment: <InputAdornment sx={{ ".MuiTypography-root": { fontSize: "small" } }} position="start">$</InputAdornment>,
                                }}
                                sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }}>
                            </TextField>

                        </div>

                        <div className='col-1' style={{ paddingRight: '0', paddingLeft: '0' }}>
                            <DeleteIcon onClick={() => deleteRow(indx)} sx={{ cursor: 'pointer' }} fontSize='medium' />
                        </div>

                    </div>
                )}
            </div>
            {
                orderDetails.vendorId &&
                <div className='row' style={{ marginTop: '20px', marginBottom: '20px', alignItems: 'end' }}>
                    <div className='col-3' style={{ margin: '0', marginLeft: '45px' }}>
                        <button id={"invoice-add-bttn"}
                            style={{ left: '2%', top: '65%', margin: '0' }}
                            onClick={onAdd}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AddOutlinedIcon fontSize='medium' sx={{ color: "#1fb6ff", marginRight: '5px', '&:hover': { color: "#14a9f3" } }} />
                                <span style={{ paddingTop: '2px' }}>ADD ITEM</span>
                            </div>
                        </button>
                    </div>

                    <div className='col-2' style={{ marginLeft: '44%' }}>

                        <TextField disabled={true} variant="standard" value={orderDetails?.totalOrderCost ?? 0.0}
                            label={'Total'}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                    fontSize: "15px"
                                },
                                "& .MuiFormHelperText-root.Mui-disabled": {
                                    WebkitTextFillColor: "#000000"
                                }, '.MuiInputLabel-root, .MuiInput-root': { fontSize: "medium" }
                            }} />

                    </div>
                </div>

            }

            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }

            <div>
                <Menu open={openStartCal} onClose={() => setStartAnchorElem(false)} anchorEl={startAnchorElem}
                    sx={{
                        ".MuiPaper-root": { borderRadius: "15px", height: "200px", width: "200px" },
                        ".MuiDateCalendar-root": { height: "100%" },
                        ".MuiList-root": { height: "200px" },
                        ".MuiButtonBase-root": { height: "210px" },
                        ".MuiPickersYear-root": { height: "20px" },
                        ".MuiTouchRipple-root": { height: "30px" },
                        ".MuiPickersCalendarHeader-root": { padding: 0, fontSize: "small", margin: '0' },
                        ".MuiPickersCalendarHeader-labelContainer": { height: "30px" },
                        ".MuiPickersArrowSwitcher-button": { padding: 0, fontSize: "medium", height: '30px' },
                        ".MuiPickersCalendarHeader-switchViewButton": { padding: 0, fontSize: "small", height: '30px' },
                        ".MuiPickersCalendarHeader-labelContainer": { padding: 0, fontSize: "small" },
                        ".MuiPickersDay-root": { height: "20px" },
                        ".MuiPickersYear-yearButton": { height: '20px', padding: 0, fontSize: "small" },
                        ".Mui-selected": { height: '20px', padding: 0, fontSize: "small" },
                        ".MuiYearCalendar-root": { width: '200px', padding: 0, fontSize: "small" }
                    }}
                >
                    <MenuItem>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={startDate ? dayjs(startDate) : dayjs(new Date())}
                                onClick={handleStartDate}
                            // onChange={(newValue) => setStartDate(newValue)} 
                            />
                        </LocalizationProvider>

                    </MenuItem>
                </Menu>
            </div>

            <Dialog maxWidth={'sm'} fullWidth open={openUploadOrderDetails} onClose={closeOpenDialog} >
                <UploadOrderDetails close={closeOpenDialog} items={handleUploadItems} totalCost={(val) => setOrderDetails(currVals => ({ ...currVals, ["totalOrderCost"]: Number(val) }))} />
            </Dialog>
        </div >
    );
};
