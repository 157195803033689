import React, { useState, useEffect } from 'react';
import useFetch from "../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid"
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog } from '@mui/material';
import PlaceInventoryOrder from "../components/VendorSubComponents/PlaceInventoryOrder.jsx"
import VendorActions from '../components/VendorSubComponents/VendorActions.jsx';
import AddNewVendor from '../components/VendorSubComponents/AddNewVendor.jsx';
import EditVendorInformation from '../components/VendorSubComponents/EditVendorInformation.jsx';
import SnackbarEx from '../components/Helpers/SnackbarEx.jsx';
import DeleteData from '../services/DeleteData.js';
import ConfirmDelete from '../components/Menu/HelperDialogs/ConfirmDelete.jsx';
import UploadedNewIngredient from '../components/Menu/HelperDialogs/UploadedNewIngredient.jsx';

export default function Vendor({ reload }) {

    const [reloadData, setReloadData] = useState(false)
    const { data: vendorList, load, error } = useFetch("api/vendors", reloadData)

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [selIngrIdArr, setSelIngrIdArr] = useState(null)

    const [enableEdit, setEnableEdit] = useState(false)

    const [deleteOpen, setDeleteOpen] = useState(false)
    const [openAddVendor, setOpenAddVendor] = useState(false)
    const [openEditVendor, setOpenEditVendor] = useState(false)
    const [openPlaceOrder, setOpenPlaceOrder] = useState(false)
    const [newIngredients, setNewIngredients] = useState(null)
    const [openNewUploadIngredientInfo, setOpenNewUploadIngredientInfo] = useState(false)

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })
    const [editIngredient, setEditIngredient] = useState({
        editEnabled: false,
        vendorId: null
    })

    useEffect(() => {
        if (vendorList !== null) {
            setData()
        }

    }, [vendorList])


    const actionOptions = (type) => {
        switch (type) {
            case "addVendor":
                setOpenAddVendor(true)
                break;

            case "placeOrder":
                setOpenPlaceOrder(true)
                break;

            case "edit":
                setOpenEditVendor(true)
                break;
        }
    }
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                {/* <GridToolbarColumnsButton /> */}
                {/* <div className=''> */}
                <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center">
                    <VendorActions selection={actionOptions} editEnable={enableEdit} />
                    <button style={{ marginTop: '0', display: "flex", alignItems: "center", height: '32px' }}
                        className={selIngrIdArr ? "delete-bttn" : "delete-dis"}
                        disabled={!selIngrIdArr}
                        onClick={() => setDeleteOpen(true)}
                    >
                        <DeleteIcon sx={{ color: selIngrIdArr ? "#FF474D" : "lightgray", '&:hover': { color: selIngrIdArr ? "#FF474D" : "lightgray" }, cursor: "pointer" }} fontSize="small" onClick={() => setDeleteOpen(true)} />
                        <span style={{ paddingTop: '2px', paddingLeft: '5px' }}>DELETE</span>
                    </button>
                </div>
                {/* </div> */}

                <GridToolbarQuickFilter
                    variant="outlined"
                    size="small"
                    sx={{
                        border: 'solid #1fb6ff', borderWidth: '1px', borderRadius: '5px', width: '225px', padding: '0', height: '32px',
                        '&.MuiFormControl-root': {
                            margin: '0'
                        }, '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent', // Removes the default border color
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                                backgroundColor: "#1976d20a" // Keeps the border color transparent on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // Ensures the border color remains the same when focused
                            },
                            '.MuiSvgIcon-root': { color: '#1fb6ff' },
                            '&.MuiInputBase-root input': { fontSize: 'small', color: '#1fb6ff', padding: '5px 0' }
                        }
                    }}
                />
            </GridToolbarContainer>
        );
    }


    const setData = () => {
        setRow(vendorList.map((e) =>
        ({
            id: e.vendorId,
            vendorName: e.vendor,
            emailOrdering: e.emailOrdering ?? "false"
        })
        ))

        setCol([
            {
                field: 'vendorName', headerName: 'Vendor Name',/* minWidth: '225', maxWidth: '225'*/ flex: 1, editable: true
            },
            {
                field: 'emailOrdering', headerName: 'Email Ordering', /*minWidth: '175', maxWidth: '175'*/ flex: 1
            },
        ]);
    }

    const selectClick = (e) => {

        if (e.length === 1) {
            setEnableEdit(e)
            setSelIngrIdArr(e)
            const findData = vendorList.find((element) => e[0] === element.vendorId)
            setEditIngredient(currVals => ({ ...currVals, vendorId: findData.vendorId }))
        } else if (e.length > 1) {
            setSelIngrIdArr(e)
            setEnableEdit(false)
        } else {
            setEnableEdit(false)
            setSelIngrIdArr(null)
        }
    }

    const closeDialog = (val) => {
        switch (true) {
            case val === "success_edit":
                setOpenEditVendor(false)
                setOpenTestSnack({ color: "success", message: "Vendor successfully updated!", open: true })
                refreshPage()
                break;

            case val === "success_add":
                setOpenAddVendor(false)
                setOpenTestSnack({ color: "success", message: "Vendor successfully added!", open: true })
                refreshPage()
                break;

            case val === "success_order":

                setOpenAddVendor(false)
                setOpenEditVendor(false)
                setOpenPlaceOrder(false)
                setOpenTestSnack({ color: "success", message: "Order successfully sent!", open: true })
                refreshPage()
                break;

            default:
                setOpenAddVendor(false)
                setOpenEditVendor(false)
                setOpenPlaceOrder(false)
                break
        }
    }

    const handleNewUploadIngredients = (newItems) => {
        setOpenNewUploadIngredientInfo(true)
        setNewIngredients(newItems)

    }

    const deleteVendor = async (bool) => {
        setDeleteOpen(false)
        if (bool) {
            const { success: vendorDeleted, error: deleteErr } = await DeleteData(`api/delete-existing-vendor`, { "vendorIds": selIngrIdArr })

            if (vendorDeleted) {
                setEnableEdit(false)
                setSelIngrIdArr(null)
                setOpenTestSnack({ color: "success", message: "Successfully deleted vendor!", open: true })
                refreshPage()
            }
        }
    }

    const refreshPage = () => {
        setReloadData(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReloadData(false)
        }, 200);
    }

    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <div>
            <div className='sticky-header'>
                <div className='row pg-hdr' style={{ width: "98%" }}>
                    <div className='col-xl-9 col-lg-8 col-md-8' /*style={{ paddingLeft: '30px' }}*/ >
                        <h2>Vendors</h2>
                        <p style={{ marginBottom: '0' }}>Add or edit vendors & place inventory orders</p>
                    </div>
                </div>
                <hr style={{ margin: '0' }} />
            </div>

            <div className='row' style={{ width: "100%" }}>
                <div className='col-12 menu-list dt' >
                    <DataGrid
                        checkboxSelection
                        onRowSelectionModelChange={selectClick}
                        rows={row}
                        columns={col}
                        rowHeight={50}
                        // processRowUpdate={(updatedRow, originalRow) => editCell(updatedRow, originalRow)}
                        onProcessRowUpdateError={(e) => (console.log(e))}
                        slots={{
                            // toolbar: GridToolbar,
                            toolbar: CustomToolbar
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'col6', sort: 'desc' }]
                            }
                        }}
                        sx={{
                            padding: "10px 0 0 25px", border: "none", marginLeft: "10px", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiDataGrid-main': { fontSize: 'small' },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiDataGrid-main': { margin: "10px 20px 0 0" },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px", paddingLeft: '0' },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "break-spaces",
                                lineHeight: "normal",
                                fontWeight: '600',
                                '@media (max-width:1080px)': { fontSize: '11px' }

                            }
                        }}
                    />
                </div>
            </div>

            <Dialog maxWidth={'sm'} fullWidth open={openAddVendor} onClose={closeDialog}>
                <AddNewVendor close={closeDialog} />
            </Dialog>

            <Dialog maxWidth={'sm'} fullWidth open={openEditVendor} onClose={closeDialog}>
                <EditVendorInformation close={closeDialog} vendId={editIngredient} />
            </Dialog>

            <Dialog fullScreen open={openPlaceOrder} onClose={closeDialog}>
                <PlaceInventoryOrder close={closeDialog} newIngredients={handleNewUploadIngredients} />
            </Dialog>

            <Dialog maxWidth={'sm'} fullWidth open={openNewUploadIngredientInfo} onClose={closeDialog} >
                <UploadedNewIngredient close={closeDialog} ingredients={newIngredients} />
            </Dialog>

            {deleteOpen && <ConfirmDelete deleteItem={deleteVendor} deleteOpen={deleteOpen} />}
            {openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                close={() => setOpenTestSnack({
                    color: "",
                    message: "",
                    open: false
                })} />}
        </div>
    );
};
