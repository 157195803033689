import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { CircularProgress } from '@mui/material';
import useFetch from "../../../services/useFetch.js"
import PostData from "../../../services/PostData.js"
import SnackbarEx from '../../Helpers/SnackbarEx.jsx';

export default function UserProfile() {

    const { data: userInfo, error, load } = useFetch("api/account-information", false)
    const [editEnabled, setEditEnabled] = useState(true)
    const [submitLoad, setSubmitLoad] = useState(false)
    const [reloadData, setReloadData] = useState(false)

    const [openSnackbar, setOpenSnackbar] = useState({
        color: "",
        message: "",
        open: false
    })

    const [alertBool, setAlertBool] = useState({
        "dailyInventoryAlert": userInfo?.inventoryAlert ?? false,
        "newStockAlert": userInfo?.newStockOrderAlert ?? false
    })

    useEffect(() => {
        if (userInfo) {
            setAlertBool(curr =>
            ({
                ...curr,
                ["dailyInventoryAlert"]: userInfo?.inventoryAlert ?? false,
                ["newStockAlert"]: userInfo?.newStockOrderAlert ?? false
            }))
        }
    }, [userInfo])

    const handleSwitchChange = (e, key) => {
        setEditEnabled(false)
        setAlertBool(curr =>
        ({
            ...curr,
            [key]: !alertBool[key],

        }))
    }

    const updateProfile = async () => {
        setSubmitLoad(true)
        const { success, error } = await PostData(alertBool, "api/update-user-profile", false)
        if (success) {
            setSubmitLoad(false)
            setOpenSnackbar({ color: "success", message: "Account preferences updated successfully!", open: true })
            refreshPage()
        } else {
            setOpenSnackbar({ color: "error", message: "Error occurred. Please try again", open: true })
        }
    }

    const refreshPage = () => {
        setReloadData(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReloadData(false)
        }, 200);
    }

    if (load) {
        return (<div className="container" style={{ left: '15%' }}><CircularProgress size={30} /></div>)
    }
    return (
        <div>
            <div className='row' style={{ padding: "5px 0 15px 15px" }}>
                <div className='col-12' style={{ padding: '0' }}>
                    <p style={{ fontSize: '20px', height: '30px', paddingLeft: '5px', margin: '0' }}>User Profile: </p>
                    <hr style={{ borderWidth: '0.5px', margin: '0', width: '315px' }} />
                </div>
            </div>

            <div className='row' style={{ paddingLeft: '15px' }}>
                <div className='col-1' style={{ minWidth: '65px' }}>
                    <label>Name: </label>
                </div>
                <div className='col-2'>
                    <p>{userInfo?.fName}</p>
                </div>

            </div>

            <div className='row' style={{ paddingLeft: '15px' }}>
                <div className='col-1' style={{ minWidth: '65px' }}>
                    <label>Email: </label>
                </div>
                <div className='col-2'>
                    <p>{userInfo?.email}</p>
                </div>

            </div>

            <div className='row' style={{ padding: '15px 0 5px 10px' }}>
                <p style={{ fontSize: '18px' }}>Notification preferences:</p>
            </div>

            <div className='row' style={{ paddingLeft: '10px', alignItems: "baseline" }}>
                <div className='col-1' style={{ minWidth: '65px' }}>
                    <Switch
                        checked={alertBool.dailyInventoryAlert}
                        onChange={(e) => handleSwitchChange(e, "dailyInventoryAlert")} />

                </div>
                <div className='col-5'>
                    <label>Daily inventory email alert</label>
                    <p style={{ fontSize: '12px' }}>Get email updates daily regarding location inventory</p>
                </div>

            </div>

            <div className='row' style={{ paddingLeft: '10px', alignItems: "baseline" }}>
                <div className='col-1' style={{ minWidth: '65px' }}>
                    <Switch
                        checked={alertBool.newStockAlert}
                        onChange={(e) => handleSwitchChange(e, "newStockAlert")} />

                </div>
                <div className='col-5'>
                    <label>New unfulfilled invoice</label>
                    <p style={{ fontSize: '12px' }}>Get email updates for invoices unfulfilled</p>
                </div>

            </div>

            <div className='row' >
                <button className='submit' type='submit' onClick={updateProfile} disabled={editEnabled}
                    style={{ width: '325px', marginTop: '16.2%', marginLeft: '22px', height: '35px' }}>
                    {!submitLoad ? 'Update Profile' : <CircularProgress size={20} sx={{ marginTop: '5px' }} />}
                </button>
            </div>

            {openSnackbar.open &&
                <SnackbarEx color={openSnackbar.color} message={openSnackbar.message} open={openSnackbar.open}
                    close={() => setOpenSnackbar({
                        color: "",
                        message: "",
                        open: false
                    })} />}
        </div>
    );
};
