import React, { useContext } from 'react';
import { authContext } from '../../context/AuthContext.js';
import { NavLink } from 'react-router-dom'
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';

export default function AccountSidebar({ back }) {

    const { usrGblRole } = useContext(authContext)
    return (
        <div className="acct-sidebar">

            <div className='row act-sbr-lnks' style={{ paddingLeft: '10px' }} >
                <NavLink to="user-profile">
                    <div className='row' style={{ alignItems: "center" }}>
                        <div className='col-2 '>
                            <AccountCircleIcon fontSize='small' sx={{ marginBottom: "6px" }} />
                        </div>
                        <div className='col-7 acct-sidebar-name'>
                            Profile
                        </div>
                    </div>
                </NavLink>

            </div >
            <div className='row act-sbr-lnks' style={{ paddingLeft: '10px' }}>
                <NavLink to="change-password" >
                    <div className='row' style={{ alignItems: "center" }}>
                        <div className='col-12' >
                            <div className='row' style={{ alignItems: "center" }}>
                                <div className='col-2 '>
                                    <MenuBookIcon fontSize='small' sx={{ marginBottom: "6px" }} />
                                </div>
                                <div className='col-7 acct-sidebar-name' style={{ textWrap: 'nowrap' }}>
                                    Change Password
                                </div>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>

            {usrGblRole && <div className='row act-sbr-lnks' style={{ paddingLeft: '10px' }} >
                <NavLink to="add-user">
                    <div className='row' style={{ alignItems: "center" }}>
                        <div className='col-2 '>
                            <AssessmentIcon fontSize='small' sx={{ marginBottom: "6px" }} />
                        </div>
                        <div className='col-7 acct-sidebar-name'>
                            Add New User
                        </div>
                    </div>
                </NavLink>
            </div >}



            {usrGblRole && <div className='row act-sbr-lnks' style={{ paddingLeft: '10px' }}>
                <NavLink to="pos-setup" >
                    <div className='row' style={{ alignItems: "center" }}>
                        <div className='col-12' >
                            <div className='row' style={{ alignItems: "center" }}>
                                <div className='col-2 '>
                                    <BadgeIcon fontSize='small' sx={{ marginBottom: "6px" }} />
                                </div>
                                <div className='col-7 acct-sidebar-name'>
                                    POS Setup
                                </div>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>}

            {usrGblRole && <div className='row act-sbr-lnks' style={{ paddingLeft: '10px' }}>
                <NavLink to="new-location-setup" >
                    <div className='row' style={{ alignItems: "center" }}>
                        <div className='col-12' >
                            <div className='row' style={{ alignItems: "center" }}>
                                <div className='col-2 '>
                                    <AddLocationOutlinedIcon fontSize='small' sx={{ marginBottom: "6px" }} />
                                </div>
                                <div className='col-7 acct-sidebar-name'>
                                    Add Locations
                                </div>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>}

            {usrGblRole && <div className='row act-sbr-lnks' style={{ paddingLeft: '10px' }}>
                <NavLink to="vendor-connect-setup" >
                    <div className='row' style={{ alignItems: "center" }}>

                        <div className='col-12' >
                            <div className='row' style={{ alignItems: "center" }}>
                                <div className='col-2 '>
                                    <ReceiptIcon fontSize='small' sx={{ marginBottom: "6px" }} />
                                </div>
                                <div className='col-9 acct-sidebar-name'>
                                    Sync Vendor Account
                                </div>
                            </div>
                        </div>
                    </div>
                </NavLink>

            </div>}

            {usrGblRole && <div className='row act-sbr-lnks' style={{ paddingLeft: '10px' }}>
                <NavLink to="merchant-setup" >
                    <div className='row' style={{ alignItems: "center" }}>

                        <div className='col-12' >
                            <div className='row' style={{ alignItems: "center" }}>
                                <div className='col-2 '>
                                    <StorefrontIcon fontSize='small' sx={{ marginBottom: "6px" }} />
                                </div>
                                <div className='col-9 acct-sidebar-name'>
                                    Merchant Information
                                </div>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>}

            <div className='row act-sbr-lnks' /*style={{ width: '90%' }} */>
                <button className='submit' style={{ fontSize: '18px', height: '35px', width: "95%" }} onClick={() => back()}>
                    <div className='col-12' style={{ paddingRight: '20px' }}>
                        <ArrowBackIcon fontSize='small' sx={{ marginBottom: "3px", marginRight: '5px' }} />
                        Back

                    </div>
                </button>
            </div>
        </div >
    );
};
