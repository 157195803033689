import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import PostData from '../../../services/PostData';
import SnackbarEx from '../../Helpers/SnackbarEx';

export default function NewIngredientParInfo({ close, ingredients }) {

    const [ingredientValue, setIngredientValue] = useState([])

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })
    const [load, setLoad] = useState(true)

    useEffect(() => {

        if (ingredients && ingredients.length > 0) {
            setLoad(false)
            const configureStateVal = ingredients.map(ingred => ({
                ingredientName: ingred.itemName,
                ingredientId: ingred.itemId,
                vendorIngredientID: ingred.vendorIngredientID ?? null,
                storeName: null,
                ingredientCategory: null,
                itemSize: ingred.itemSize,
                recipeUoM: ingred.recipeUoM,
                parUoM: ingred.parUoM,
                parThresholdUoM: null,
                parThreshold: null
            }))
            setIngredientValue(prevVals => [...prevVals, ...configureStateVal]);
        } else {
            console.log("No ingredients found");
        }
    }, [])
    const menuCategories = [
        "Coffee",
        "Dairy",
        "Disposables",
        "Dry Goods",
        "Tea",
        "Meat",
        "Produce",
        "Bread",
        "Chemicals/Cleaning",
        "Retail",
        "Other"]

    const handleValueChange = (val, index, objKey) => {

        const newIngredient = [...ingredientValue]

        if (objKey === "ingredientName") {
            newIngredient[index][objKey] = val.ingredient
            setIngredientValue(...ingredientValue, newIngredient)
        } else if (objKey === "parThreshold") {
            const inputValue = val.target.value
            newIngredient[index] = { ...newIngredient[index], [objKey]: Number(inputValue) };
            setIngredientValue(newIngredient)
        }
        else {
            const inputValue = val.target.value
            newIngredient[index] = { ...newIngredient[index], [objKey]: inputValue };
            setIngredientValue(newIngredient)
        };
    }

    const validateForm = () => {
        const allFilled = ingredientValue.every(value => value.ingredientCategory
            && value.parThresholdUoM && value.parThreshold && value.itemSize);
        return allFilled;
    };

    const handleSubmit = async () => {

        setLoad(true)
        const valid = validateForm()

        if (valid) {
            const { success, error } = await PostData(ingredientValue, 'api/update-par-details', false)
            if (success) {
                close("parDetails", "new")
                setLoad(false)
            }
        } else {
            setLoad(false)
            setOpenTestSnack({ color: "error", message: "Please fill out all information", open: true })
        }

    }

    if (load) {
        return (<div className="container" style={{ top: "5px", left: "45%" }}><CircularProgress /></div>)
    }

    return (
        <div className='container-fluid d-flex flex-column' style={{ height: '60vh' }}>
            <div className='row' style={{ justifyContent: "center" }}>
                <div className='col-12' style={{ paddingTop: '15px', paddingLeft: '35px' }}>
                    <h4 style={{ marginBottom: "0" }}>New Ingredient Detected: </h4>
                </div>
                {/* <div className='col-3' style={{ position: 'relative', left: "70px", paddingTop: '15px' }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={() => close('exitIcon')} />
                    </button>
                </div> */}

            </div>
            <div className='row'>
                <p style={{ paddingLeft: "35px", margin: '0' }}>Additional information needed for ingredients.</p>
            </div>
            <hr style={{ marginBottom: "25px" }} />

            <div className='row' style={{ marginBottom: "25px" }}>
                {ingredients.map((ing, index) => (
                    <div className='row' style={{ marginBottom: "15px" }}>
                        <div className='col-3' style={{ position: 'relative', left: '3.5%', marginBottom: "12px", width: "525px" }}>
                            <TextField
                                disabled
                                // helperText="Ingredient"
                                value={ing.itemName ? ing.itemName : ""}
                                variant="standard"
                                onChange={(val) => handleValueChange(val, index, "ingredientName")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            :
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000"
                                    },
                                    "& .MuiFormHelperText-root.Mui-disabled": {
                                        WebkitTextFillColor: "#000000"
                                    }
                                }} />
                        </div>

                        <div className='row' style={{ marginLeft: "5px", marginBottom: '10px' }}>
                            <div className='col-5' style={{ maxWidth: '215px' }}>
                                <TextField
                                    required
                                    label="Store Item Name"

                                    value={ingredientValue[index].storeName ? ingredientValue[index].storeName : ""}
                                    // value={"test"}
                                    size='small'
                                    sx={{ minWidth: "200px" }}
                                    onChange={(val) => handleValueChange(val, index, "storeName")}>
                                </TextField>
                            </div>


                            <div className='col-5' >

                                <TextField
                                    required
                                    select
                                    label="Ingredient Category"
                                    value={ingredientValue[index].ingredientCategory ? ingredientValue[index].ingredientCategory : ""}
                                    // value={"test"}
                                    size='small'
                                    sx={{ width: "200px" }}
                                    onChange={(val) => handleValueChange(val, index, "ingredientCategory")}>
                                    {menuCategories.map((option, ind) =>
                                        <MenuItem key={ind} value={option}>
                                            {option}
                                        </MenuItem>
                                    )}
                                </TextField>

                            </div>

                        </div>
                        <div className='row' style={{ marginLeft: "5px" }}>
                            <div className='col-5' style={{ maxWidth: '215px' }} >
                                <TextField
                                    required
                                    label="PAR Threshold"
                                    value={ingredientValue[index].parThreshold ? ingredientValue[index].parThreshold : ""}
                                    // value={"test"}
                                    size='small'
                                    sx={{ minWidth: "200px", fontSize: '10px' }}
                                    onChange={(val) => handleValueChange(val, index, "parThreshold")}>
                                </TextField>
                            </div>

                            <div className='col-5' >
                                <TextField
                                    required
                                    label="PAR Packaging"
                                    value={ingredientValue[index].parThresholdUoM ? ingredientValue[index].parThresholdUoM : ""}
                                    // value={"test"}
                                    size='small'
                                    sx={{ minWidth: "200px" }}
                                    onChange={(val) => handleValueChange(val, index, "parThresholdUoM")}>
                                </TextField>
                            </div>
                        </div>



                    </div>
                ))}


            </div>
            <div className='row' style={{ paddingBottom: "20px", justifyContent: 'flex-start', position: "relative", left: "3%", marginTop: "auto" }}>
                <button className='submit' type='submit' style={{ width: "90%" }} onClick={handleSubmit}>
                    Submit
                </button>
            </div>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div >
    );
};
