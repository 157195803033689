import React, { useState, useEffect, useContext } from 'react'
import { Route, Routes, useNavigate, Navigate, redirect } from "react-router-dom"
import { SidebarContext } from './context/SidebarContext.js';
import { LocationContext } from './context/LocationContext.js';
import { roleContext } from './context/RoleContext.js';
import { authContext } from './context/AuthContext.js'
import ConfigureMerchant from './pages/ConfigureMerchant.jsx';
import Sidebar from './pages/Sidebar';
import Notfound from './pages/Notfound.jsx';
import Header from './pages/Header';
import Menu from './pages/Menu';
import Dashboard from './pages/Dashboard';
import Inventory from './pages/Inventory';
import InvoiceHistory from "./pages/InvoiceHistory"
import WastedInventory from './pages/WastedInventory';
import Authorization from './pages/Authorization';
import ResetPassword from './pages/ResetPassword.jsx';
import Account from './pages/Account.jsx';
import AddUser from './components/Account/AccountPages/AddUser.jsx';
import ResetPasswordAccount from './pages/ResetPasswordAccount.jsx';
import PrivateOutlet from './components/Authorization/PrivateOutlet';
import useFetch from "./services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';
import BusinessInfo from './components/Account/AccountPages/BusinessInfo.jsx';
import ChangePassword from './components/Account/AccountPages/ChangePassword.jsx';
import OrdersLog from './pages/OrdersLog.jsx';
import POSSetup from './components/Account/AccountPages/POSSetup.jsx';
import SquareAuth from './components/Account/AccountPages/OAuths/SquareAuth.jsx';
import ShopifyAuth from './components/Account/AccountPages/OAuths/ShopifyAuth.jsx';
import AddVendor from './components/Account/AccountPages/AddVendor.jsx';
import AddLocation from './components/Account/AccountPages/AddLocation.jsx';
import Vendor from './pages/Vendor.jsx';
import VendorStockOrderLog from './pages/VendorStockOrderLog.jsx';
import UserProfile from './components/Account/AccountPages/UserProfile.jsx';
import NewAccountResetPassword from './pages/NewAccountResetPassword.jsx';
import AppAdministration from './pages/AppAdministration.jsx';
import { QuickBooksAuth } from './components/Account/AccountPages/OAuths/QuickBooksAuth.jsx';
import QuickbooksSetupPage from './components/MerchantConfig/QuickbooksModule/QuickbooksSetupPage.jsx';


export default function App() {
  const nav = useNavigate()
  const { toggleSidebar, isOpen } = useContext(SidebarContext)
  const { setLocationAccess, locationAccess } = useContext(LocationContext)
  const [key, setKey] = useState(false);

  const { isAuthenticated, changeAuth, changeUsrRole, usrGblRole, updateUserRole, userRole } = useContext(authContext)

  const [load, setLoad] = useState(true)

  const [usrName, setUsrName] = useState("")
  const [sdbrEnable, setSdbEnable] = useState(true)

  const { data, error } = useFetch('api/userauth', false)

  useEffect(() => {

    if (!data || data === 'User is not authenticated') {
      return
    }

    else if (data.auth) {
      setUsrName(data.usr)
      if (data.role === "GlobalAdmin" || data.role === "AppAdmin") {
        updateUserRole(data.role)
        changeUsrRole(true)
        setLoad(false)
        changeAuth(true)
        setLocationAccess(data.locationAccess)
      }
      else {
        updateUserRole(data.role)
        setLoad(false)
        changeAuth(true)
        setLocationAccess(data.locationAccess)
      }
    } else {
      setLoad(false)
    }
  }, [data])

  const addNewLocation = (loc) => {
    setLocationAccess([...locationAccess, loc])
    toggleSidebar()
  }
  const userLogin = (authenticated, locs, usr, role) => {

    setUsrName(usr)
    // setAcctLocs(locs)
    setLocationAccess(locs)
    if (!isOpen) {
      toggleSidebar()
    }

    localStorage.setItem("usrlctn", locs[0])
    if (role === "GlobalAdmin") {

      changeUsrRole(true)
      changeAuth(authenticated)
      nav("/analytics")
    }
    else {
      changeUsrRole(false)
      changeAuth(authenticated)
      nav("/analytics")
    }
  }

  const logout = (bool) => {
    if (bool) {
      changeAuth(false)
      return redirect("/login")
    }
  }

  if (load) {
    return (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative", top: "40%" }}>
        <CircularProgress />
        <p style={{ marginTop: "8px", color: "#1976d2", width: "max-content" }}>Loading...</p>
      </div>
    )
  }
  return (
    <div className="app">
      <div className='row' style={{ flexDirection: 'column' }}>
        {isAuthenticated && <Header logout={logout} usrName={usrName} locations={locationAccess} reload={() => setKey(!key)} />}
      </div>

      <div className='row' style={{ flexDirection: 'column', height: '100vh' }}>
        <roleContext.Provider value={usrGblRole}>

          <div className="main-content">

            {(isAuthenticated && isOpen) &&
              <Sidebar />
            }
            <div className="content">
              <div>
                <Routes>
                  <Route exact path='/' element={<PrivateOutlet isAllowed={!!isAuthenticated} />} >

                    <Route index element={<Navigate to="/inventory" replace />} />

                    <Route path="/analytics" element={<Dashboard reload={key} />} />
                    <Route path="/orders-log" element={<OrdersLog reload={key} />} />
                    <Route path="/menu" element={<Menu reload={key} />} />

                    <Route path="/vendors" element={<Vendor reload={key} />} />
                    <Route path="/stock-orders-log" element={<VendorStockOrderLog reload={key} />} />
                    <Route path="/inventory" element={<Inventory reload={key} />} />
                    <Route path="/invoices" element={<InvoiceHistory reload={key} />} />
                    <Route path="/unsold-items" element={<WastedInventory reload={key} />} />
                    <Route path="/account-settings" element={<Account home={() => toggleSidebar()} />} >
                      <Route index element={<UserProfile />} />
                      <Route path="/account-settings/user-profile" element={<UserProfile locationAccess={locationAccess} reset={() => setSdbEnable(true)} />} />
                      <Route path="/account-settings/change-password" element={<ChangePassword />} />
                    </Route>
                  </Route>

                  <Route element={<PrivateOutlet isAllowed={!!isAuthenticated && usrGblRole} redirectPath='/inventory' />} >
                    <Route path="/configure-locations" element={<ConfigureMerchant reload={key} />} />
                    <Route path="/configure-quickbooks" element={<QuickbooksSetupPage reload={key} />} />
                    <Route path="/square-auth" element={<SquareAuth />} />
                    <Route path="/shopify-auth" element={<ShopifyAuth />} />
                    <Route path="/qb-redirect" element={<QuickBooksAuth />} />

                    <Route path="/account-settings" element={<Account home={() => toggleSidebar()} />} >
                      <Route index element={<UserProfile />} />
                      <Route path="/account-settings/user-profile" element={<UserProfile locationAccess={locationAccess} reset={() => setSdbEnable(true)} />} />
                      <Route path="/account-settings/add-user" element={<AddUser locationAccess={locationAccess} reset={() => setSdbEnable(true)} />} />
                      <Route path="/account-settings/change-password" element={<ChangePassword />} />
                      <Route path="/account-settings/pos-setup" element={<POSSetup />} />
                      <Route path="/account-settings/vendor-connect-setup" element={<AddVendor />} />
                      <Route path="/account-settings/new-location-setup" element={<AddLocation locations={addNewLocation} />} />
                      <Route path="/account-settings/merchant-setup" element={<BusinessInfo />} />
                    </Route>
                  </Route>

                  <Route element={<PrivateOutlet isAllowed={!!isAuthenticated && userRole === 'AppAdmin'} redirectPath='/inventory' />} >
                    <Route path="/app-administration" element={<AppAdministration />} />
                  </Route>

                  <Route path="/login" element={<Authorization login={userLogin} />} />
                  <Route path="/reset-password/account/:tokenKey" element={<ResetPasswordAccount />} />
                  <Route path="/reset-password/new-account/:tokenKey" element={<NewAccountResetPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />

                  <Route path="/signup" element={<Authorization />} />
                  <Route path="*" element={<Notfound />} />
                </Routes>

              </div>

            </div>
          </div>

        </roleContext.Provider>

      </div>
      <div >
      </div >
    </div >

  );
}
