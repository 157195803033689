/* Need to pull in ingredients for the merchant -- have to know which locations it's tied to */
import React, { useState, useEffect } from 'react';
import useSetup from "../../services/useSetup.js"
import { DataGrid } from "@mui/x-data-grid"
import CircularProgress from '@mui/material/CircularProgress';
import SetupCustomToolBar from './SetupCustomToolBar.jsx';
import { Dialog } from "@mui/material";
import CopyRecipes from '../Menu/HelperDialogs/CopyRecipes.jsx';
import SnackbarEx from '../Helpers/SnackbarEx.jsx';

export default function IngredientConfig({ selLoc, setLoc }) {
    const [refresh, setRefresh] = useState(false)

    const [selAction, setSelAction] = useState(null)
    const [selIngrIdArr, setSelIngrIdArr] = useState([])

    const { data: merchantIngredients, load, error } = useSetup(`api/configuration/ingredients/${selLoc}`, refresh)

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    useEffect(() => {
        if (merchantIngredients !== null) {
            setData()
        }
    }, [merchantIngredients])


    const setData = () => {
        setRow(merchantIngredients?.map((e) =>
        ({
            id: e.ingredientId,
            col1: e.ingredient,
            storeName: e.storeName,
            category: e.category,
            col2: e.vendor,
        })
        ))

        setCol([
            {
                field: 'col1', headerName: 'Ingredient', flex: 1
            },
            {
                field: 'storeName', headerName: 'Store Name', flex: 1
            },
            { field: 'category', headerName: 'Category', flex: 1 },
            { field: 'col2', headerName: 'Vendor', flex: 1 },
        ]);
    }
    const changeLoc = (val) => {
        setLoc(val)
        setRefresh(true)
        setTimeout(() => {
            setRefresh(false)
        }, 200)
    }

    function CustomToolbar() {
        return (
            <SetupCustomToolBar actionChoice={(act) => setSelAction(act)} selLoc={changeLoc} locVal={selLoc} selItem={selIngrIdArr} />
        )
    }

    const selectClick = (e) => {

        if (e.length === 1) {
            setSelIngrIdArr(e)
        } else if (e.length > 1) {
            setSelIngrIdArr(e)
        } else {
            setSelIngrIdArr([])
        }
    }

    const handleClose = (bool, val) => {
        setSelAction(false)
        if (val === "copied") {
            setOpenTestSnack({ color: "success", message: "Successfully copied item!", open: true })
            setSelIngrIdArr([])
            refreshPage()
        }
    }
    const refreshPage = () => {
        setRefresh(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setRefresh(false)
        }, 200);
    }

    if (load || !merchantIngredients) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <div style={{ height: '48vh' }}>
            <div className='row' style={{ width: "100%" }}>
                <div className='col-12 menu-list dt' style={{ marginTop: '10px' }}>
                    <DataGrid
                        checkboxSelection
                        onRowSelectionModelChange={selectClick}
                        rowSelectionModel={selIngrIdArr}
                        rows={row}
                        columns={col}
                        rowHeight={40}
                        slots={{
                            toolbar: CustomToolbar
                        }}
                        pageSizeOptions={[30]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 30,
                                },
                            },
                        }}
                        sx={{
                            padding: "10px 0 0 25px", border: "none", marginLeft: "10px", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '&.MuiDataGrid-root': { height: '70vh', paddingTop: '0' },
                            '.MuiDataGrid-main': { fontSize: 'small' },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiDataGrid-main': { margin: "10px 20px 0 0" },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px", paddingLeft: '0' },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "break-spaces",
                                lineHeight: "normal",
                                fontWeight: '600',
                                '@media (max-width:1080px)': { fontSize: '11px' }

                            }
                        }}
                    />
                </div>
            </div>
            <Dialog fullScreen open={selAction} onClose={handleClose} >
                <CopyRecipes close={handleClose} selIngredients={selIngrIdArr} copyType={"ingredient"} bbLoc={selLoc} />
            </Dialog>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div>
    );
};
