import { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import PostData from '../../../../services/PostData.js'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const QuickBooksAuth = () => {
    const location = useLocation();
    const [nav, setNav] = useState(false)
    const [load, setLoad] = useState(false)
    const [codeParam, setCodeParam] = useState(null)
    const [realmIdParam, setRealmIdParam] = useState(null)

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const authCode = urlParams.get('code');
        const realmId = urlParams.get('realmId');
        const state = urlParams.get('state');

        if (authCode && realmId) {
            setCodeParam(authCode)
            setRealmIdParam(realmId)
        }
    }, [location]);
    useEffect(() => {
        if (codeParam && realmIdParam) {
            postAuthCode();
        }
    }, [codeParam]);

    const postAuthCode = async () => {
        setLoad(true)
        const { success, error } = await PostData({ code: codeParam, realmId: realmIdParam, vendor: "quickbooks" }, 'api/quickbooks-auth-token', false)
        if (success) {
            setLoad(false)
            setNav(true)
        }
    }

    return (
        <>
            <Backdrop
                sx={{ flexDirection: 'column', color: 'rgb(31, 182, 255)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={load}
                onClick={() => setLoad(false)}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
                <p style={{ marginTop: "8px", color: "#fff" }}>Connecting...</p>
            </Backdrop>

            <div style={{ position: 'relative', top: '50%', left: '40%' }}>
                <p style={{ color: 'lightgray' }}>Processing...</p>
            </div>
            {nav && (
                <Navigate to="/configure-quickbooks" replace={true} />
            )}
        </>
    )
};
