import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent } from "@mui/material";
import PostPDF from '../../../services/PostPDF.js';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from "@mui/x-data-grid"
import Snackbar from "@mui/material/Snackbar"
import SnackbarEx from "../../Helpers/SnackbarEx.jsx"
import Alert from '@mui/material/Alert';
import ImportCSVFile from './ImportCSVFile.jsx';

export default function AddPDFFile({ close, newIngredients }) {

    const [pdfFile, setPdfFile] = useState(null)
    const [load, setLoad] = useState(false)

    const [csvUploadVendor, setCsvUploadVendor] = useState({ "VendorName": null, "InvoiceNum": null })

    const [pdfCheck, setPdfCheck] = useState(null)
    const [csvCheck, setCsvCheck] = useState(null)
    const [badHeaders, setBadHeaders] = useState(false)
    const [loadFileLookup, setLoadFileLookup] = useState(false)
    const [notAdded, setNotAdded] = useState(null)
    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const apiUrl = 'api/upload-invoice'

    useEffect(() => {
        if (notAdded) {
            setData()
        }
    }, [notAdded])

    const handleClose = () => {
        close('closeUpload')
    }

    const handleFile = ({ target }) => {
        const f = target.files[0]
        setPdfFile(f)
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        setLoad(true)

        try {
            const formData = new FormData()
            formData.append("file", pdfFile, pdfFile.name)
            if (csvCheck) {
                if (csvUploadVendor.VendorName && csvUploadVendor.InvoiceNum) {
                    const { success: CSVSuccess, unknowns: CSVUnknowns, error: CSVError } = await PostPDF(formData, apiUrl, csvUploadVendor)

                    if (CSVError) {
                        const errMessage = CSVError.response.data
                        setOpenTestSnack({ color: "error", message: errMessage, open: true })
                        setLoad(false)
                        // close('failure')
                    }
                    else if (CSVSuccess && CSVUnknowns.length > 0) {
                        setLoad(false)
                        close('success')
                        newIngredients(CSVUnknowns)
                    }
                    else if (CSVSuccess) {
                        setLoad(false)
                        close('success')
                    }
                } else {
                    setOpenTestSnack({ color: "error", message: "Please add a vendor and invoice number!", open: true })
                }

            } else {
                const { success: PDFSuccess, unknowns: PDFUnknowns, error: PDFError } = await PostPDF(formData, apiUrl, null)
                if (PDFSuccess && PDFUnknowns) {
                    setLoad(false)
                    close('pdfUpload', PDFUnknowns)
                    // setNotAdded(PDFUnknowns)
                }
                else if (PDFSuccess) {
                    setLoad(false)
                    close('pdfUpload')
                } else if (PDFError) {
                    setLoad(false)
                    setOpenTestSnack({ color: "error", message: PDFError.response.data, open: true })
                }
            }
        } catch (err) { console.log(err) }
        return
    }

    const setData = () => {
        setRow(notAdded.map((el, ind) =>
            ({ id: ind, col1: el })
        ))
        setCol([
            { field: 'col1', headerName: 'Unknown Item(s)', flex: 1 }
        ]);
    }

    const handleCsvClick = ({ target }) => {
        const checked = target.checked

        setCsvCheck(checked)
        setPdfCheck(!checked)
    }
    const handlePdfClick = ({ target }) => {
        const checked = target.checked

        setPdfCheck(checked)
        setCsvCheck(!checked)
    }

    const updateVendorSelect = (val, key) => {
        setCsvUploadVendor(prev => ({
            ...prev,
            [key]: val
        }))
    }

    if (load) {
        return (
            <DialogContent >
                <div className="container" style={{ left: '5px', top: '10px' }}><CircularProgress sx={{ marginLeft: '15px', marginBottom: '10px' }} />
                    <p style={{ fontSize: '12px' }}>Submitting...</p>
                </div>
            </DialogContent>
        )
    }
    return (
        <div>
            {!notAdded ?
                <>
                    <DialogContent sx={{ borderRadius: "20px" }} >
                        <div className='row' >
                            <div className='col-10'>
                                <p style={{ fontSize: "20px" }}>Select File Type + Upload:</p>
                            </div>
                            <div className='col-2'>
                                <CloseIcon fontSize='medium' onClick={handleClose} sx={{ cursor: 'pointer' }} />
                            </div>

                        </div>
                        <hr style={{ marginTop: "0" }} />

                        {/* Checkboxes to decide which type of file will be added by the end user */}
                        <div className='row' style={{ alignItems: 'center', paddingBottom: "15px" }}>
                            <div className='col-1'>
                                <Checkbox sx={{ padding: "0" }} checked={csvCheck} onChange={handleCsvClick} />
                            </div>
                            <div className='col-3'>
                                <p style={{ margin: '0' }}>CSV file</p>
                            </div>
                            <div className='col-1'>
                                <Checkbox sx={{ padding: "0" }} checked={pdfCheck} onChange={handlePdfClick} /*disabled */ />
                            </div>
                            <div className='col-4'>
                                <p style={{ margin: '0' }}>PDF file</p>
                            </div>
                        </div>

                        {csvCheck &&
                            <ImportCSVFile vendorSel={(vend, key) => updateVendorSelect(vend, key)} />
                        }

                        <div className='row'>
                            <button style={{
                                background: "none", width: "150px", borderWidth: '.5px',
                                height: '35px', borderRadius: "5px", marginLeft: '12px', cursor: "pointer"
                            }} disabled={!csvCheck && !pdfCheck} >
                                <label htmlFor="file-upload" style={{ paddingTop: '2px', cursor: "pointer" }}>Choose file</label>
                                <input type="file" name="file"
                                    disabled={!csvCheck && !pdfCheck}
                                    onChange={handleFile} accept={csvCheck ? '.csv, .xls' : '.pdf' /*'.pdf, .jpg'*/}
                                    style={{ opacity: '0', right: '56%', position: 'absolute', width: '150px' }} />
                            </button>
                        </div>

                        {loadFileLookup &&
                            <>
                                <CircularProgress sx={{ position: 'relative', left: '40%', marginTop: '15px' }} />
                                <p style={{ left: '35%', position: 'relative', width: 'max-content', marginTop: '8px', fontSize: '12px', color: 'gray' }}>Checking file...</p>
                            </>
                        }

                        {pdfFile &&
                            <div className='row' style={{ marginTop: '15px' }}>
                                <p style={{ margin: '0 0 0 10px' }}>File:<em> {pdfFile.name}</em></p>
                            </div>
                        }

                        <div style={{ marginTop: '30px' }}>
                            <button className='submit'
                                style={{ width: csvCheck ? "100%" : "350px", margin: '0', cursor: "pointer" }}
                                onClick={handleUpload}>Upload</button>
                        </div>
                    </DialogContent>
                </>
                :
                <>
                    <DialogContent sx={{ borderRadius: "20px" }} >
                        <div className='row' >
                            <div className='col-10'>
                                <p style={{ fontSize: "20px", margin: '0', color: '#CC0000' }}>Unknown Items:</p>
                                <p style={{ fontSize: "14px", color: '#CC0000' }}>*Please add items and try again*</p>
                            </div>
                            <div className='col-2'>
                                <CloseIcon fontSize='medium' onClick={handleClose} sx={{ cursor: 'pointer' }} />
                            </div>
                            <hr style={{ margin: "0" }} />
                            <div className='row' style={{ marginTop: '0' }}>
                                <div className="col-12" style={{ height: "25vh" }} >
                                    <DataGrid
                                        rows={row}
                                        columns={col}
                                        rowHeight={35}
                                        pageSize={10}
                                        pageSizeOptions={[10, 25]}
                                        hideFooter
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 10,
                                                },
                                            },
                                        }}
                                        sx={{
                                            padding: "10px 0 0 25px", border: "none", overflowY: "scroll",
                                            '.MuiToolbar-root': { alignItems: "baseline" },
                                            '.MuiSvgIcon-root': { margin: 0 },
                                            '&.MuiDataGrid-main': { marginRight: "20px" },
                                            '&.MuiDataGrid-root': { paddingLeft: "0" },
                                            '.MuiDataGrid-columnHeaderTitle': { fontSize: '14px', fontWeight: 'bold 900' },
                                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                                            '.MuiDataGrid-cellContent': { fontSize: "small" }
                                        }} />
                                </div>
                            </div >

                        </div>
                        <hr style={{ marginTop: "0" }} />
                    </DialogContent>
                </>
            }
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={badHeaders}
                autoHideDuration={4000}
                onClose={() => setBadHeaders(false)}>
                <Alert onClose={() => setBadHeaders(false)} severity="error">Unsupported Headers in File</Alert>
            </Snackbar>
            {openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                close={() => setOpenTestSnack({
                    color: "",
                    message: "",
                    open: false
                })} />}

        </div>
    );
};
